import React, { useCallback, useState } from "react";
import { BlockStack, Box, Button, Divider, Icon, InlineStack, List, Modal, Text } from "@shopify/polaris";
import { AddNoteMajor, ViewMinor } from "@shopify/polaris-icons";
import { useAuthenticatedFetch } from "@/Api/Axios";

export default function ShowResumeScore({ row, jobData, childRef }) {
  const [summaryActive, setSummaryActive] = useState(false);
  const [showResumeActive, setShowResumeActive] = useState(false);
  const fetch = useAuthenticatedFetch();

  const toggleSummary = useCallback(() => setSummaryActive((popoverActive) => !popoverActive), []);
  const toggleResume = useCallback(() => setShowResumeActive((popoverActive) => !popoverActive), []);

  const getResumeScore = async (candidate) => {
    const data = {
      jobData,
      candidateResume: candidate?.resume?.assetUrl,
      candidateId: candidate?._id,
      candidate,
    };
    await fetch.post(`/candidateResumeScore`, data);
    childRef?.current?.fetchData();
  };

  return (
    <BlockStack gap={300}>
      {row?.resumeScore?.summary && (
        <Button monochrome onClick={() => toggleSummary(row)}>
          <InlineStack gap={100}>
            <Icon source={AddNoteMajor}></Icon>
            <Text fontWeight="medium"> Resume Summary</Text>
          </InlineStack>
        </Button>
      )}
      {!row?.resumeScore?.score && (
        <Button monochrome onClick={() => getResumeScore(row)}>
          <InlineStack gap={100}>
            <Icon source={AddNoteMajor}></Icon>
            <Text fontWeight="medium">Get Resume Score</Text>
          </InlineStack>
        </Button>
      )}
      {row?.resume?.assetUrl?.includes(".pdf") && (
        <Button monochrome onClick={() => toggleResume(row)}>
          <InlineStack gap={100}>
            <Icon source={ViewMinor} />
            <Text fontWeight="medium">View Resume</Text>
          </InlineStack>
        </Button>
      )}
      <Modal
        open={summaryActive}
        onClose={toggleSummary}
        size="large"
        title={
          row?.fullName
            ? `${row?.fullName?.charAt(0)?.toUpperCase() + row?.fullName?.slice(1)}'s summary`
            : "summary"
        }
      >
        <Modal.Section>
          <div style={{ fontSize: "15px", padding: "10px" }}>
            <BlockStack gap={300}>
              <Text>
                <b>Resume Summary : </b>
                {row?.resumeScore?.summary}
              </Text>
              <Divider />

              <InlineStack gap={600}>
                <Box minWidth="30%" maxWidth="48%">
                  <style
                    dangerouslySetInnerHTML={{
                      __html: `.Polaris-List--typeNumber{padding-left:15px;} .Polaris-List__Item{font-size:inherit} .Polaris-Text--bodySm{max-width: 24rem}`,
                    }}
                  />
                  <BlockStack gap={300}>
                    <Text fontWeight="bold">Matched Skills</Text>
                    <Divider />
                    <List type="number" padding="0">
                      {row?.resumeScore?.mentions_points?.map((e, index) => (
                        <List.Item key={index}>{e}</List.Item>
                      ))}
                    </List>
                  </BlockStack>
                </Box>

                <Box maxWidth="48%">
                  <BlockStack gap={300}>
                    <Text fontWeight="bold">Unmatched Skills</Text>
                    <Divider />
                    <List type="number" padding="0">
                      {row?.resumeScore?.not_mentions_points?.map((e, index) => (
                        <List.Item key={index}>{e}</List.Item>
                      ))}
                    </List>
                  </BlockStack>
                </Box>
              </InlineStack>
            </BlockStack>
          </div>
        </Modal.Section>
      </Modal>
      <Modal
        open={showResumeActive}
        onClose={toggleResume}
        size="large"
        title={`${row?.fullName ? `${row?.fullName}'s` : ""} resume`}
      >
        <div style={{ height: "calc(100vh - 250px)" }}>
          <object
            data={`https://${process.env.VULTR_BUCKETNAME}.ewr1.vultrobjects.com/${row?.resume?.assetUrl}`}
            type="application/pdf"
            width="100%"
            height="100%"
          >
            <p>PDF cannot be displayed.</p>
          </object>
        </div>
      </Modal>
    </BlockStack>
  );
}
